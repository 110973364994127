import { createModel } from '@rematch/core'
import { sectionEndpoints } from './endpoints'
import {
  CategoryTypeApiResponse,
  SectionApiResponse,
  SectionPayload,
  SectionRowType,
  TableDataType,
} from './types'
import { TopicType } from '../../store/models/dictionary'
import { Alert } from 'rsuite'
import { TABLE_ROW_INIT } from './constants'

export type SectionState = {
  isLoading: boolean
  table: TableDataType
  hasMore: boolean
  instagramUpdateDate: string
  filters: SectionPayload
  categories: CategoryTypeApiResponse
  editRow: SectionRowType
  editMode: number
  addMode: boolean
  newTopics: string[]
  checkedMode: boolean
  checked: number[]
  organization: number
}

const initialState: SectionState = {
  isLoading: false,
  table: [],
  hasMore: false,
  instagramUpdateDate: null,
  filters: {
    sectionType: null,
    regionCode: null,
    regionCodeDashboard: null,
    category: null,
    mediaTypesDashboard: null,
    loyalty: null,
    loyaltyDashboard: null,
    loyaltyAnalytics: null,
    typetask: null,
    topics: null,
    socialNetwork: null,
    areaNetwork: null,
    skip: null,
    period: null,
    hasOwner: null,
    partnership: null,
    cooperationDashboard: null,
    startDate: null,
    endDate: null,
    startDateDashboard: null,
    endDateDashboard: null,
  },
  categories: [],
  editRow: TABLE_ROW_INIT,
  editMode: null,
  addMode: false,
  newTopics: [],
  checkedMode: false,
  checked: [],
  organization: null,
}

export const section = createModel<SectionState>()({
  state: initialState,
  reducers: {
    updateTable(state: SectionState, table: TableDataType): SectionState {
      return {
        ...state,
        table: [...table],
      }
    },
    setLoading(state, isLoading: boolean): SectionState {
      return {
        ...state,
        isLoading,
      }
    },
    setHasMore(state: SectionState, hasMore: boolean): SectionState {
      return {
        ...state,
        hasMore,
      }
    },
    setInstagramUpdateDate(
      state: SectionState,
      instagramUpdateDate: string,
    ): SectionState {
      return {
        ...state,
        instagramUpdateDate,
      }
    },
    updateFilters(state: SectionState, filters: SectionPayload): SectionState {
      return {
        ...state,
        filters: { ...state.filters, ...filters },
      }
    },
    updateCategories(
      state: SectionState,
      categories: CategoryTypeApiResponse,
    ): SectionState {
      return {
        ...state,
        categories: [...categories],
      }
    },
    updateEditRow(state: SectionState, updated): SectionState {
      return {
        ...state,
        editRow: updated,
      }
    },
    setEditMode(state: SectionState, editMode: number): SectionState {
      return {
        ...state,
        editMode: editMode,
      }
    },
    setAddMode(state: SectionState, addMode: boolean): SectionState {
      return {
        ...state,
        addMode: addMode,
      }
    },
    resetEditRow(state: SectionState): SectionState {
      return {
        ...state,
        editRow: initialState.editRow,
      }
    },
    addNewTopic(state: SectionState, topic: TopicType): SectionState {
      return {
        ...state,
        editRow: {
          ...state.editRow,
          topics: [...state.editRow.topics, ...[topic]],
        },
      }
    },
    removeTopic(state: SectionState, topic: TopicType): SectionState {
      return {
        ...state,
        editRow: {
          ...state.editRow,
          topics: state.editRow.topics.filter((item) => item.id !== topic.id),
        },
      }
    },
    setChecked(state: SectionState, id: number): SectionState {
      const ids = id
        ? state.checked.includes(id)
          ? state.checked.filter((item) => item !== id)
          : [...state.checked, id]
        : null
      return {
        ...state,
        checked: ids,
      }
    },
    setCheckedAll(state: SectionState): SectionState {
      const allIds = state.table.map((row) => row.id)
      return {
        ...state,
        checked: state.checked.length
          ? state.checked.length < state.table.length
            ? allIds
            : []
          : allIds,
      }
    },
    setCheckedMode(state: SectionState, checkedMode: boolean): SectionState {
      return {
        ...state,
        checkedMode,
      }
    },
    resetChecked(state: SectionState): SectionState {
      return {
        ...state,
        checked: [],
      }
    },
    setOrganization(state: SectionState, organization: number): SectionState {
      return {
        ...state,
        organization,
      }
    },
  },
  effects: (dispatch) => ({
    async loadTable(
      {
        sectionType,
        regionCode,
        category,
        skip = 0,
        typetask,
        period,
        topics,
        hasOwner,
        partnership,
        loyaltyAnalytics,
        areaNetwork,
      }: SectionPayload,
      rootState,
    ) {
      const sectionObj = {
        sectionType,
        regionCode,
        category,
        skip,
        isMonitoring: 'true',
        isSocialFieldControl: 'true',
        period,
        topics,
        hasOwner,
        partnership,
        loyaltyAnalytics,
        areaNetwork,
      }
      if (typetask && typetask.indexOf('isMonitoring') < 0) {
        sectionObj.isMonitoring = 'false'
      }
      if (typetask && typetask.indexOf('isSocialFieldControl') < 0) {
        sectionObj.isSocialFieldControl = 'false'
      }
      if ((typetask && typetask.length === 0) || !typetask) {
        sectionObj.isMonitoring = null
        sectionObj.isSocialFieldControl = null
      }

      dispatch.section.setLoading(true)
      try {
        const result: SectionApiResponse = await sectionEndpoints.getTableData(
          sectionObj,
        )
        dispatch.section.setHasMore(result.hasMore)
        dispatch.section.setInstagramUpdateDate(result.instagramUpdateDate)
        if (skip > 0) {
          dispatch.section.updateTable([
            ...rootState.section.table,
            ...result.items,
          ])
        } else {
          dispatch.section.updateTable(result.items)
        }
      } catch (e) {
        console.error('Ошибка загрузки данных')
      } finally {
        dispatch.section.setLoading(false)
      }
    },
    async saveRow(row, rootState) {
      const updatedTable = rootState.section.table.map((item) =>
        item.id === row.id ? row : item,
      )
      this.updateTable(updatedTable)
      /* eslint-disable */
      try {
        const result = await sectionEndpoints.update(row)
        this.setEditMode(null)
        this.setEditRow({})
        return new Promise((resolve, reject) => {
          resolve(result)
        })
      } catch (error) {
        if (error.response.data.code === 409) {
          if (
            error.response.data.message ===
            'Link already exists in federal region'
          ) {
            Alert.error('Ошибка. Такой источник уже есть на федеральном уровне')
          } else {
            Alert.error('Ошибка. Возможно, такая ссылка уже есть в инфокарте')
          }
        }
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
      /* eslint-enable */
    },
    async insertRow(row, rootState) {
      try {
        await sectionEndpoints.insert(row)
        await this.loadTable(rootState.section.filters)
        this.setAddMode(false)
      } catch (error) {
        if (error.response.data.code === 409) {
          if (
            error.response.data.message ===
            'Link already exists in federal region'
          ) {
            Alert.error('Ошибка. Такой источник уже есть на федеральном уровне')
          } else {
            Alert.error('Ошибка. Возможно, такая ссылка уже есть в инфокарте')
          }
        }
      }
    },
    async deleteRow(id, rootState) {
      const updatedTable = rootState.section.table.filter(
        (item) => item.id !== id,
      )
      this.updateTable(updatedTable)
      await sectionEndpoints.remove(id)
      this.setEditMode(null)
    },
    async deleteRows(payload, rootState) {
      const updatedTable = rootState.section.table.filter(
        (item) => !rootState.section.checked.includes(item.id),
      )
      this.updateTable(updatedTable)
      await sectionEndpoints.remove(rootState.section.checked.join(','))
      this.resetChecked()
      this.setEditMode(null)
    },
    setEditRow(row) {
      this.updateEditRow(row)
      this.setEditMode(row.id)
    },
    toggleCheckedMode(id, rootState) {
      this.setCheckedMode(!rootState.section.checkedMode)
      this.resetChecked()
    },
  }),
})
