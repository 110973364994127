import { createModel } from '@rematch/core'

import { requestApi } from '../../../helpers/api'

const STATUS_NOT_LOADED = 0
const STATUS_LOADING = 1
const STATUS_LOADED = 2

export type Status =
  | typeof STATUS_NOT_LOADED
  | typeof STATUS_LOADING
  | typeof STATUS_LOADED

export type Payload = {
  force?: boolean
  dictionary: string
  params?: unknown[]
}

const initialState: DictionaryState = {
  status: {
    regions: STATUS_NOT_LOADED,
    topics: STATUS_NOT_LOADED,
    topicTypes: STATUS_NOT_LOADED,
  },
  regions: [],
  topics: { topics: {} },
  topicTypes: [],
}

export type Region = {
  regionCode: string
  regionName: string
}

export type TopicTypesType = {
  id: number
  organizationId: number
  name: string
}

export type PublicPageTopic = {
  id: number
  name: string
  topicTypeId: number
}

export type TopicsDictionaryType = {
  topics: {
    [key: number]: PublicPageTopic[]
  }
}

export type TopicType = {
  id: number
  name: string
}

export type SocialType = {
  value: number
  label: string
}

export type DictionaryType = {
  value: number
  label: string
}[]

export type DictionaryState = {
  status: {
    regions: Status
    topics: Status
    topicTypes
  }
  regions: Region[]
  topics: TopicsDictionaryType
  topicTypes: TopicTypesType[]
}

type SetPayload = {
  dictionary: string
  data: unknown[]
}

type SetLoadingPayload = {
  dictionary: string
  isLoading: boolean
}

export const dictionary = createModel<DictionaryState>()({
  state: initialState,
  reducers: {
    setDictionary(state, { dictionary, data }: SetPayload): DictionaryState {
      return {
        ...state,
        [dictionary]: data,
      }
    },
    setLoading(
      state,
      { dictionary, isLoading }: SetLoadingPayload,
    ): DictionaryState {
      return {
        ...state,
        status: {
          ...state.status,
          [dictionary]: isLoading,
        },
      }
    },
    reset(): DictionaryState {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async fetchDictionary(
      { force, dictionary, params }: Payload,
      state,
    ): Promise<unknown> {
      if (force || state.dictionary.status[dictionary] === STATUS_NOT_LOADED) {
        dispatch.dictionary.setLoading({
          dictionary,
          isLoading: STATUS_LOADING,
        })
        try {
          const result = await requestApi('get', `/${dictionary}`, params)
          dispatch.dictionary.setDictionary({ dictionary, data: result })
          dispatch.dictionary.setLoading({
            dictionary,
            isLoading: STATUS_LOADED,
          })
          return true
        } catch (e) {
          dispatch.dictionary.setLoading({
            dictionary,
            isLoading: STATUS_NOT_LOADED,
          })
          return false
        }
      }
    },
  }),
})
