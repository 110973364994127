import React, { useEffect, useRef } from 'react'
import { styled } from 'linaria/react'
import { Wrapper } from '../../ui/Wrapper'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { ROUTES } from '../sidebar/constants'
import { Region } from '../../store/models/dictionary'
import { redirect } from '../../helpers/redirect'
import { SearchResultType } from '../section/types'
import { pluralize } from '../../helpers/text'

type SearchProps = {
  data: SearchResultType
  query: string
  setQuery: (query) => void
}

export const DropDownWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 5px;
  width: 500px;
  padding: 20px;
  background: #25262a;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 20px 20px;
  z-index: 100;
  max-height: 380px;
  min-height: 380px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #1a1a1e;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #727382;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a3a9;
  }

  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
  }
`

const SearchFooter = styled(Wrapper)`
  justify-content: space-between;
  position: absolute;
  left: 0px;
  top: 345px;
  width: 500px;
  padding: 20px;
  background: #1a1a1e;
  border-radius: 0px 0px 20px 20px;
  z-index: 100;
  overflow: auto;
  font-size: 14px;

  a {
    color: #fff;
    font-size: 14px;
  }
`

export const MediumTitle = styled.span`
  font-size: 12px;
`

export const Icon = styled.img`
  margin-right: 20px;
  width: 40px;
`

export const Separator = styled.div`
  margin: 0 10px;
  width: 1px;
  height: 18px;
  color: #fff;
  background: #fff;
`

const RowName = styled.span`
  font-size: 15px;
`

export const FoundRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    .row_name {
      color: #fff;
    }
    background: #1a1a1e;
  }
`

const ShowAllResults = styled.span`
  text-decoration: underline;
  color: #fff;
  cursor: pointer;
`

export const SearchDropDown: React.FunctionComponent<SearchProps> = ({
  data,
  query,
  setQuery,
}) => {
  const WrapRef = useRef(null)
  const regions = useSelector<RootState, Region[]>(
    (state) => state.dictionary.regions,
  )
  const items = data.items

  useEffect(() => {
    ;[...WrapRef.current.querySelectorAll('*')].forEach((obj) => {
      obj.setAttribute('data-block', 'search')
    })
  }, [items])

  return (
    <Wrapper ref={WrapRef}>
      <DropDownWrapper>
        <Wrapper direction={'column'} padding={'0 0 30px 0'}>
          {items.map((item) => {
            return (
              <FoundRow
                key={item.id}
                onClick={() => {
                  redirect(
                    ROUTES.find((route) => route.mediaType === item.sectionType)
                      .route +
                      '/' +
                      item.id +
                      '/' +
                      item.regionCode,
                  )
                }}
              >
                <Icon
                  src={
                    ROUTES.find((route) => route.mediaType === item.sectionType)
                      .icon
                  }
                  alt={
                    ROUTES.find((route) => route.mediaType === item.sectionType)
                      .name
                  }
                />
                <Wrapper direction={'column'} align={'flex-start'}>
                  <RowName className={'row_name'}>{item.groupName}</RowName>
                  <Wrapper justify={'flex-start'} margin={'5px 0 0 0'}>
                    <MediumTitle>
                      {
                        regions.find(
                          (region) => region.regionCode === item.regionCode,
                        ).regionName
                      }
                    </MediumTitle>
                    <Separator />
                    <MediumTitle>
                      {
                        ROUTES.find(
                          (route) => route.mediaType === item.sectionType,
                        ).name
                      }
                    </MediumTitle>
                  </Wrapper>
                </Wrapper>
              </FoundRow>
            )
          })}
          {!items.length && <div>Ничего не найдено</div>}
        </Wrapper>
      </DropDownWrapper>
      <SearchFooter>
        {items.length < 10 && (
          <span>
            Найдено: {items.length}{' '}
            {pluralize(items.length, 'результат', 'результата', 'результатов')}
          </span>
        )}
        {items.length >= 10 && (
          <>
            <span>Показано 10 записей</span>
            <ShowAllResults
              onClick={() => {
                setQuery({ text: '' })
                redirect('/search/' + query)
              }}
            >
              Показать все результаты &#129042;
            </ShowAllResults>
          </>
        )}
      </SearchFooter>
    </Wrapper>
  )
}
