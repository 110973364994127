import { createModel } from '@rematch/core'
import {
  COLUMNS_CRK_PERSON,
  COLUMNS_CRK_SOCIAL,
  COLUMNS_MEDIA,
  COLUMNS_MESSENGERS,
  COLUMNS_NEW_WAVE_POLITICS,
  COLUMNS_PERSON,
  COLUMNS_SOCIAL,
  COLUMNS_TOP30,
} from '../../../features/section/constants'

export type GeneralState = {
  sidebarOpened: boolean
  colsEnabled: Record<string, string[]>
}

const initialState: GeneralState = {
  sidebarOpened: true,
  colsEnabled: {
    news: COLUMNS_SOCIAL.map((item) => item.code),
    discussions: COLUMNS_SOCIAL.map((item) => item.code),
    media: COLUMNS_MEDIA.map((item) => item.code),
    blogs: COLUMNS_SOCIAL.map((item) => item.code),
    influencers: COLUMNS_PERSON.map((item) => item.code),
    liders: COLUMNS_PERSON.map((item) => item.code),
    CrkSocialNetworks: COLUMNS_CRK_SOCIAL.map((item) => item.code),
    CrkBloggers: COLUMNS_CRK_PERSON.map((item) => item.code),
    organizations: COLUMNS_SOCIAL.map((item) => item.code),
    toppublics: COLUMNS_SOCIAL.map((item) => item.code),
    messengers: COLUMNS_MESSENGERS.map((item) => item.code),
    newWavePolitics: COLUMNS_NEW_WAVE_POLITICS.map((item) => item.code),
    top30: COLUMNS_TOP30.map((item) => item.code),
  },
}

export const general = createModel<GeneralState>()({
  state: initialState,
  reducers: {
    setSidebarOpened(state, opened: boolean): GeneralState {
      return {
        ...state,
        sidebarOpened: opened,
      }
    },
    setColsEnabled(state, rows: Record<string, string[]>): GeneralState {
      return {
        ...state,
        colsEnabled: { ...state.colsEnabled, ...rows },
      }
    },
  },
  effects: () => ({
    updateColsEnabled(rows: Record<string, string[]>, rootState) {
      this.setColsEnabled(rows)
      localStorage.setItem(
        'colsEnabled',
        JSON.stringify({ ...rootState.general.colsEnabled, ...rows }),
      )
    },
  }),
})
