import React from 'react'

import { initializeStore } from './store'
import { InitConfigRedux } from '@rematch/core'
import { NextPage } from 'next'

const isServer = typeof window === 'undefined'
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'

function getOrCreateStore(initialState?: InitConfigRedux) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState)
  }

  // Store in global variable if client
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState)
  }
  return window[__NEXT_REDUX_STORE__]
}

export type Store = ReturnType<typeof getOrCreateStore>

type Props = { reduxStore: Store }

export const withReduxStore = (
  Component: React.FunctionComponent<Props>,
): NextPage => {
  return class Redux extends React.Component<Props> {
    private readonly reduxStore

    static async getInitialProps(appContext) {
      const reduxStore = getOrCreateStore()

      // Provide the store to getInitialProps of pages
      appContext.ctx.reduxStore = reduxStore

      let appProps = {}
      /* eslint-disable */
      if ((Component as any).getInitialProps) {
        appProps = await (Component as any).getInitialProps(appContext)
      }
      /* eslint-enable */
      return {
        ...appProps,
        initialReduxState: reduxStore.getState(),
      }
    }

    constructor(props) {
      super(props)
      this.reduxStore = getOrCreateStore(props.initialReduxState)
    }

    render() {
      return <Component {...this.props} reduxStore={this.reduxStore} />
    }
  }
}
