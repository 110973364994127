import { createModel } from '@rematch/core'
import { dashboardEndpoints } from './endpoints'
import {
  DashboardCategoryFilterType,
  DashboardDataCardType,
  DashboardDiagramType,
} from './types'
import { SectionPayload } from '../section/types'
import { convertData, convertFormat } from './helpers'

export type DashboardState = {
  isLoading: boolean
  data: DashboardDataCardType
  summary: DashboardDiagramType
  categoryList: DashboardCategoryFilterType
  socialNetwork: []
  socialNetworkApply: []
}

const initialState: DashboardState = {
  isLoading: false,
  data: null,
  summary: null,
  categoryList: [],
  socialNetwork: [],
  socialNetworkApply: [],
}

export const dashboard = createModel<DashboardState>()({
  state: initialState,
  reducers: {
    updateDashboard(
      state: DashboardState,
      data: DashboardDataCardType,
    ): DashboardState {
      return {
        ...state,
        data: data,
      }
    },
    updateSummary(
      state: DashboardState,
      summary: DashboardDiagramType,
    ): DashboardState {
      return {
        ...state,
        summary,
      }
    },
    updateCategoryList(
      state: DashboardState,
      list: DashboardCategoryFilterType,
    ) {
      return {
        ...state,
        categoryList: list,
      }
    },
    updateIsLoading(state: DashboardState, isLoading: boolean) {
      return {
        ...state,
        isLoading,
      }
    },
    updateSocialNetwork(state: DashboardState, socialNetwork: []) {
      return {
        ...state,
        socialNetwork,
      }
    },
    updateSocialNetworkApply(state: DashboardState, socialNetworkApply: []) {
      return {
        ...state,
        socialNetworkApply,
      }
    },
  },
  effects: () => ({
    loadDashboard(
      {
        regionCodeDashboard,
        loyaltyDashboard,
        startDateDashboard,
        endDateDashboard,
        mediaTypesDashboard,
        cooperationDashboard,
      }: SectionPayload,
      getState,
    ) {
      if (!regionCodeDashboard) {
        this.updateDashboard(null)
        return false
      }

      const mediaTypesDashboardDefault = getState.dashboard.categoryList
        .filter((itm) => itm.organizationId === 1)
        .map((obj) => obj.id)
        .join(',')

      const getDashboardRun = async () => {
        this.updateIsLoading(true)
        const resultDash = await dashboardEndpoints.getDashboard(
          regionCodeDashboard,
          loyaltyDashboard,
          mediaTypesDashboard,
          mediaTypesDashboardDefault,
          cooperationDashboard,
        )
        // Преоборазовать формат данных для конвертации
        const resultFormat = convertFormat(resultDash)
        // Конвертировать формат данных для дашборда
        const resultData = convertData(resultFormat)
        // добавить данные в store
        this.updateDashboard(resultData)
        this.updateIsLoading(false)
      }

      const getSummaryDiagram = async () => {
        const resultSummary = await dashboardEndpoints.getSummaryDiagram(
          regionCodeDashboard,
          startDateDashboard,
          endDateDashboard,
          mediaTypesDashboard,
          mediaTypesDashboardDefault,
        )
        this.updateSummary(resultSummary)
      }

      getDashboardRun()
      getSummaryDiagram()
    },
    applyBtnClick(_, getState) {
      const filters = getState.section.filters
      this.loadDashboard(filters)

      if (
        filters.regionCodeDashboard &&
        filters.regionCodeDashboard.length === 0
      ) {
        this.updateDashboard(null)
        this.updateSummary(null)
      }
    },
    async loadCategoryFilter() {
      const result = await dashboardEndpoints.getCategoryFilter()
      this.updateCategoryList(result)
    },
  }),
})
