import { Models } from '@rematch/core'

import { general } from './general'
import { dictionary } from './dictionary'
import { user } from './user'
import { section } from '../../features/section/model'
import { dashboard } from '../../features/dashboard/model'
import { users } from '../../features/users/model'

export interface RootModel extends Models {
  user: typeof user
  general: typeof general
  dictionary: typeof dictionary
  section: typeof section
  dashboard: typeof dashboard
  users: typeof users
}

export const models: RootModel = {
  user,
  general,
  dictionary,
  section,
  dashboard,
  users,
}
