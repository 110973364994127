'use client'
import { UserType } from '../features/users/types'

export const Helpdesk = (user: UserType): void => {
  // установил jquery через npm и подключил таким спобом
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.$ = window.jQuery = require('jquery')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.ATL_JQ_PAGE_PROPS = $.extend(window.ATL_JQ_PAGE_PROPS, {
    fieldValues: {
      components: '12103',
      customfield_10300: '11',
      ...(user && {
        email: user?.email || '',
        fullname: user?.fio || '',
      }),
    },
  })
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  $.getScript(
    'https://helpdesk.dialog.info/plugins/servlet/issueCollectorBootstrap.js?collectorId=0991e45b&locale=ru_RU',
  )
}
