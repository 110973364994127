import { requestApi } from '../../helpers/api'
import {
  SearchPayloadType,
  SearchResultType,
  SectionApiResponse,
  SectionPayload,
  SectionRowType,
} from './types'
import { TopicType } from '../../store/models/dictionary'

export const sectionEndpoints = {
  getTableData: function ({
    sectionType,
    regionCode,
    category,
    skip,
    isMonitoring,
    isSocialFieldControl,
    period,
    topics,
    hasOwner,
    partnership,
    loyaltyAnalytics,
    areaNetwork,
  }: SectionPayload): Promise<SectionApiResponse> {
    return requestApi<SectionApiResponse>('get', '/section', {
      sectionType,
      regionCode: Array.isArray(regionCode) ? regionCode.join(',') : regionCode,
      categories: category && category.join(','),
      skip,
      isMonitoring,
      isSocialFieldControl,
      startDate: period && new Date(period[0]).toISOString(),
      endDate: period && new Date(period[1]).toISOString(),
      topics: topics && topics.join(','),
      hasOwner,
      partnerships: partnership && partnership.join(','),
      loyalties: loyaltyAnalytics && loyaltyAnalytics.join(','),
      types: areaNetwork && areaNetwork.join(','),
    })
  },
  update: async function (row: SectionRowType): Promise<void> {
    return await requestApi<void>('put', '/section/update', row)
  },
  insert: async function (row: SectionRowType): Promise<void> {
    return await requestApi<void>('post', '/section/insert', row)
  },
  remove: function (itemId: string): Promise<void> {
    return requestApi<void>('delete', '/section/delete', { itemId })
  },
  search: function (query: SearchPayloadType): Promise<SearchResultType> {
    return requestApi<SearchResultType>('get', '/section/search', query)
  },
  insertTopic: function (name: string): Promise<TopicType> {
    return requestApi<TopicType>('post', '/topics/insert', { name })
  },
}
