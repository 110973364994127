import { DISTRICTS } from '../section/constants'
import {
  DashboardApiResponseType,
  convertDiagramType,
  convertSocialElParam,
  summDataDiagramProp,
} from './types'

// найти название региона по его номеру id
const searchDist = (val: string) => {
  // рузультат поиска
  let sresult = null
  // цикл по всем округам
  DISTRICTS.forEach((obj) => {
    // найти название по коду
    const ser = obj.regions.filter((itm) => itm.code === val)
    // если найдено название то присвоить его
    sresult = ser.length !== 0 ? ser[0].name : sresult
  })
  // цикл по регионам
  if (sresult === null) {
    const ser = DISTRICTS.filter((itm) => itm.code === val)
    sresult = ser.length !== 0 ? ser[0].name : sresult
  }
  return sresult
}

// суммировать количество подписчиков по типу соцсетей
// количество подписчиков одинаковых соцсетей должны быть суммированы
const summDataDiagram = (val: summDataDiagramProp) => {
  // список уникальных id типов соцсетей
  const listType = []
  // сформировать список
  val.forEach((obj) => {
    // если нет id в списке
    if (listType.findIndex((itm) => itm === obj.groupType) < 0) {
      // добавить id в список
      listType.push(obj.groupType)
    }
  })
  // суммировать количество
  // подписчиков по типу соцсетей
  const result = listType.map((obj) => {
    // сумма подписчиков
    let summSoc = 0
    // вычислить сумму
    val.forEach((itm) => {
      if (itm.groupType === obj) {
        summSoc += itm.subscribersCount
      }
    })
    // вернуть объет с суммарным
    // количеством подписчиков
    return {
      groupType: obj,
      subscribersCount: summSoc,
    }
  })
  return result
}

// преобразовать данные для state
export const convertData = (
  result: DashboardApiResponseType,
): Record<string, unknown> => {
  // объект с данными
  const vals = {
    diagram: [],
    // .......
  }
  // консолидировать данные в один объект
  // + добавить название региона каждому элементу
  for (const key in result) {
    for (const keyTwo in result[key]) {
      // если нет нужного ключа в объекте то создать
      if (vals[keyTwo] === undefined) vals[keyTwo] = []
      // добавить данные в нужный ключ
      vals[keyTwo].push(
        ...result[key][keyTwo].map(
          // добавить название региона в объект
          (obj: Record<string, unknown>) => ({
            ...obj,
            district: searchDist(key),
          }),
        ),
      )
    }
  }
  // преобразовать данные для ключа diagram
  vals.diagram = summDataDiagram(vals.diagram)
  //
  return vals
}

// Отсортировать элементы по убыванию
// а так же выводить максимум 5 топовых элементов
export const sortGroup = (
  val: Record<string, []>,
  valKey: string,
): Record<string, []> => {
  // тип для аргументов
  type sortArg = { valKey: number }
  // объект с отсортированными данными
  const sortObj = {}
  // отсортировать данные
  for (const key in val) {
    // cкопировать элементы
    sortObj[key] = [...val[key]]
    // сортировать по убыванию
    sortObj[key].sort((a: sortArg, b: sortArg) => b[valKey] - a[valKey])
  }
  // выводить максимум 5 топовых элементов
  for (const key in sortObj) {
    if (sortObj[key].length > 5) {
      sortObj[key] = sortObj[key].slice(0, 5)
    }
  }
  //
  return sortObj
}

// Преоборазовать формат данных для конвертации
export const convertFormat = (dataServer: {
  regions: Record<string, unknown>
}): DashboardApiResponseType => {
  // модель для преобразования данных
  const convertModel = {
    subscribers: [
      { from: 'Facebook', to: 'facebook' },
      { from: 'Odnoklassniki', to: 'odnoklassniki' },
      { from: 'Youtube', to: 'youtube' },
      { from: 'Vk', to: 'vkontakte' },
      { from: 'Instagram', to: 'instagram' },
      { from: 'Telegram', to: 'telegram' },
      { from: 'Twitter', to: 'twitter' },
      { from: 'Tiktok', to: 'tiktok' },
      { from: 'Viber', to: 'viber' },
      { from: 'YandexZen', to: 'yandexZen' },
      { from: 'Rutube', to: 'rutube' },
      { from: 'MassMedia', to: 'smi' },
    ],
    er: [
      { from: 'Vk', to: 'vkontakteEr' },
      { from: 'Instagram', to: 'instagramEr' },
    ],
    views: [
      { from: 'Vk', to: 'vkontakteViewsEr' },
      { from: 'Instagram', to: 'instagramViewsEr' },
    ],
  }
  // конвертировать данные для соцсетей
  const convertSocial = (el: convertSocialElParam[], type: string) => {
    return el.map((obj) => {
      return {
        er: obj.er,
        id: obj.id,
        link: obj.link,
        socialNetworkType: obj.socialNetworkType,
        viewsEr: obj.viewsEr,
        loyalty: obj.loyalty,
        name: obj.name,
        subscriberCount: obj.subscriberCount,
        type: Number(type),
        partnership: obj.partnership,
      }
    })
  }
  // конвертировать данные диаграммы
  const convertDiagram = (el: convertDiagramType[]) => {
    //
    return el.map((obj) => ({
      groupType: obj.groupType,
      subscribersCount: obj.subscribersCount,
    }))
  }
  // объект с новым форматом данных
  const dataConvert = {}
  //
  for (const key in dataServer.regions) {
    // создать структуру объекта
    dataConvert[key] = {
      diagram: convertDiagram(dataServer.regions[key]['diagram']),
      facebook: [],
      odnoklassniki: [],
      youtube: [],
      vkontakte: [],
      vkontakteEr: [],
      vkontakteViewsEr: [],
      instagram: [],
      instagramEr: [],
      instagramViewsEr: [],
      telegram: [],
      twitter: [],
      tiktok: [],
      viber: [],
      yandexZen: [],
      rutube: [],
      smi: [],
    }
    // конвертировать данные для соцсетей
    for (const keyTwo in dataServer.regions[key]['mediaTypes']) {
      // объект региона
      const el = dataServer.regions[key]['mediaTypes'][keyTwo]
      // subscribes
      convertModel.subscribers.forEach((itm) => {
        dataConvert[key][itm.to] = [
          ...dataConvert[key][itm.to],
          ...convertSocial(el.subscribers.publicStats[itm.from], keyTwo),
        ]
      })
      // er
      convertModel.er.forEach((itm) => {
        dataConvert[key][itm.to] = [
          ...dataConvert[key][itm.to],
          ...convertSocial(el.er.publicStats[itm.from], keyTwo),
        ]
      })
      // viewsEr
      convertModel.views.forEach((itm) => {
        dataConvert[key][itm.to] = [
          ...dataConvert[key][itm.to],
          ...convertSocial(el.views.publicStats[itm.from], keyTwo),
        ]
      })
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return dataConvert
}
