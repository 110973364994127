import { createModel } from '@rematch/core'
import { UserApiResponse } from './types'
import { userEndpoints } from '../../store/models/user/endpoints'
import { Alert } from 'rsuite'

export type UsersState = {
  isLoading: boolean
  users: UserApiResponse
}

const initialState: UsersState = {
  isLoading: false,
  users: [],
}

export const users = createModel<UsersState>()({
  state: initialState,
  reducers: {
    updateUsers(state: UsersState, users: UserApiResponse): UsersState {
      return {
        ...state,
        users: users,
      }
    },
  },
  effects: (dispatch) => ({
    async loadUsers() {
      const result = await userEndpoints.getAllUsers()
      dispatch.users.updateUsers(result)
    },
    updateUser(user, rootState) {
      const updatedUser = rootState.users.users.map((item) =>
        item.id === user.id ? user : item,
      )
      userEndpoints.updateUser(user)
      this.updateUsers(updatedUser)
    },
    async deleteUser(userId, rootState) {
      try {
        await userEndpoints.deleteUser(userId)
        Alert.success('Пользователь удален')
      } catch (e) {
        Alert.error('Произошла ошибка')
      }
      this.updateUsers(
        rootState.users.users.filter((item) => item.id !== userId),
      )
    },
  }),
})
