import { AppProps } from 'next/app'
import { Store } from 'redux'
import Head from 'next/head'
import React, { useEffect } from 'react'
import { withReduxStore } from '../store/with-redux-store'
import { Provider } from 'react-redux'
import { AppTemplate } from '../ui/AppTemplate'
import 'rsuite/lib/styles/index.less'
import '../ui/global.css'
import * as Sentry from '@sentry/browser'
import { RewriteFrames } from '@sentry/integrations'
import getNextConfig from 'next/config'
import { redirect } from '../helpers/redirect'
import {
  isBlocked,
  isNoVisibleRegions,
  isUser,
  isUserOrganization,
} from '../helpers/user'
import { Sidebar } from '../features/sidebar'
import { Header } from '../features/header'
import { useMatomo } from '../helpers/matomo'
import { ROUTES } from '../features/sidebar/constants'
import { getCookie } from '../helpers/cookie'
import { Helpdesk } from '../helpers/helpdesk'

const config = getNextConfig()
const { SENTRY_DSN, RELEASE } = config.publicRuntimeConfig

if (SENTRY_DSN) {
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`
  Sentry.init({
    release: RELEASE,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next')
          return frame
        },
      }),
    ],
    dsn: SENTRY_DSN,
  })

  Sentry.configureScope((scope) => {
    scope.setTag('ssr', process.browser ? 'no' : 'yes')
  })
}

interface Props {
  reduxStore: Store
}

function MyApp({ Component, pageProps, reduxStore }: AppProps & Props) {
  const user = reduxStore.getState().user
  useMatomo(user.id, user.fio)

  const collapsed = getCookie('sidebarCollapsed')

  useEffect(() => {
    user.id && Helpdesk(user)
  }, [])

  return (
    <Provider store={reduxStore}>
      <Head>
        <title>Инфокарта</title>
      </Head>
      <AppTemplate>
        <Sidebar isCollapsed={!!collapsed} />
        <Header />
        <Component {...pageProps} />
      </AppTemplate>
    </Provider>
  )
}

MyApp.getInitialProps = async ({ Component, ctx, router }) => {
  let pageProps = {}
  const { reduxStore } = ctx
  const target = ctx.req?.path || router.pathname
  await reduxStore.dispatch.user.getCurrent()
  const user = reduxStore.getState().user

  if (!isUser(reduxStore) && target !== '/login' && target !== '/logout') {
    redirect('/login', ctx)
  } else if (user.fio && !reduxStore.getState().section.organization) {
    // // todo refactor(move to section page)

    // выставляем организацию
    let orgCookie = parseInt(getCookie('organization') as string) || null
    if (
      orgCookie &&
      !user.organizationAccess.hasOwnProperty(orgCookie) &&
      !user.isSuperuser
    ) {
      orgCookie = null
    }
    const sectionName = router.query.sectionName as string
    const organizationSelected =
      orgCookie ||
      (ROUTES.find((route) => route.code === sectionName)
        ?.organization as number)

    let org = orgCookie
    if (!org) {
      org =
        sectionName && isUserOrganization(organizationSelected, reduxStore)
          ? organizationSelected
          : parseInt(Object.keys(user.organizationAccess)[0])
    }

    // проверяем, может ли сюда попасть юзер с выбранной организацией
    if (
      ROUTES.find(
        (route) => route.organization === org && route.code === sectionName,
      ) === undefined &&
      sectionName !== undefined
    ) {
      const url =
        ROUTES.find((route) => route.organization === org)?.route || '/'
      redirect(url, ctx)
    }

    await reduxStore.dispatch.section.setOrganization(org)
  }

  if (
    (isBlocked(reduxStore) || isNoVisibleRegions(reduxStore)) &&
    !['/blocked', '/login'].includes(target) &&
    !process.browser
  ) {
    redirect('/blocked', ctx)
  }

  await Promise.all([
    reduxStore.dispatch.dictionary.fetchDictionary({
      dictionary: 'regions',
    }),
    reduxStore.dispatch.dictionary.fetchDictionary({
      dictionary: 'topicTypes',
    }),
    reduxStore.dispatch.dictionary.fetchDictionary({
      dictionary: 'topics',
    }),
  ])

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }
  return { pageProps }
}

export default withReduxStore(MyApp)
