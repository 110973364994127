import React from 'react'
import { Content } from './Content'
import { isBlocked, isNoVisibleRegions, isUser } from '../helpers/user'
import { useStore } from 'react-redux'
import { useRouter } from 'next/router'
import Login from '../pages/login'
import LogoutPage from '../pages/logout'
import { UserBlocked } from './UserBlocked'

export const AppTemplate: React.FunctionComponent = ({ children }) => {
  const store = useStore()
  const router = useRouter()

  if (router.pathname === '/logout') {
    return <LogoutPage />
  }

  if (!isUser(store)) {
    return <Login />
  }

  let warningText = ''
  if (isNoVisibleRegions(store)) {
    warningText = 'Не выбраны доступные регионы. Обратитесь к администратору.'
  } else if (isBlocked(store)) {
    warningText =
      'Ваша учетная запись заблокирована. Обратитесь к администратору.'
  }

  if (warningText && isUser(store)) {
    return <UserBlocked warningText={warningText} />
  }

  return <Content>{children}</Content>
}
