import React, { useState } from 'react'
import { styled, StyledComponent } from 'linaria/react'
import { useRouter } from 'next/router'
import { DEFAULT_BACKGROUND, DEFAULT_TEXT_COLOR } from '../../ui/constants'
import router from '../../routes'
import { ROUTES } from './constants'
import { MenuItemProps, SidebarLinkProps } from './types'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { Alert } from 'rsuite'
import { UserOrganization } from '../section/constants'
import { setCookie } from '../../helpers/cookie'

const { Link } = router

export const SidebarContainer = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 70px;
  bottom: 0;
  left: 0;
  flex-shrink: 0;
  padding: 30px;
  z-index: 300;
  background: ${DEFAULT_BACKGROUND};
  height: calc(100vh - 70px);
`

const MenuItem: StyledComponent<MenuItemProps> = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 10px;
  background: ${(p) => (p.selected ? '#4566f6' : DEFAULT_BACKGROUND)};
  color: ${(p) => (p.selected ? '#fff' : DEFAULT_TEXT_COLOR)};
  border-radius: 8px;
  height: 48px;

  &:hover {
    color: #fff;
  }
`

const StyledLink = styled.a`
  display: block;
  cursor: pointer;
  max-width: 200px;
  margin-bottom: 12px;
  text-align: center;
  color: #9593a0;
  font-size: 14px;
  position: relative;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
  &:focus {
    text-decoration: none;
  }
`

const Text = styled.div`
  font-weight: 700;
  padding-left: 20px;
  text-align: left;
  color: inherit;
  line-height: 18px;
`

const Icon = styled.img`
  min-width: 28px;
  max-width: 28px;
`

const Collapse = styled.div`
  width: 200px;
  overflow: hidden;
  transition: 300ms;

  &.collapsed {
    width: 50px;
  }
`

const Arrow = styled.img`
  position: absolute;
  bottom: 8%;
  transition: 500ms;
  cursor: pointer;
  max-width: 40px;

  &.collapsed {
    transform: rotate(180deg);
  }
`

export const SidebarLink: React.FunctionComponent<SidebarLinkProps> = ({
  route,
  icon,
  name,
  code,
  organization,
}) => {
  const editMode = useSelector<RootState, number>(
    (state) => state.section.editMode,
  )
  const selectedOrganization = useSelector<RootState, number>(
    (state) => state.section.organization,
  )
  const router = useRouter()
  const path =
    router.pathname === '/'
      ? 'dashboard'
      : router.pathname === '/section'
      ? router.query.sectionName
      : ''
  const selected = path == code

  const editModeAlert = (e) => {
    if (editMode) {
      Alert.error(
        'Вы находитесь в режиме редактирования, сохраните запись',
        3000,
      )
      e.preventDefault()
    }
  }

  // скрываем не доступные для юзера элементы меню
  if (organization !== selectedOrganization && code !== 'dashboard') {
    return null
  }

  // скрываем от ЦРК Дашборд
  if (selectedOrganization === UserOrganization.Crk && code === 'dashboard') {
    return null
  }

  return (
    <Link route={route} passHref>
      <StyledLink onClick={(e) => editModeAlert(e)}>
        <MenuItem selected={selected}>
          <Icon src={icon} alt={name} />
          <Text>{name}</Text>
        </MenuItem>
      </StyledLink>
    </Link>
  )
}

export const Sidebar: React.FunctionComponent<{ isCollapsed: boolean }> = ({
  isCollapsed,
}) => {
  const [collapsed, setCollapsed] = useState(isCollapsed)

  const sidebarCollapse = () => {
    const date = new Date()
    const collapsedValue = !collapsed ? 'collapsed' : ''
    localStorage.setItem('sidebarCollapsed', collapsedValue)
    setCookie('sidebarCollapsed', collapsedValue, {
      expires: new Date(date.setMonth(date.getMonth() + 1)),
    })
    setCollapsed(!collapsed)
  }

  const collapsedClass = collapsed ? 'collapsed' : ''
  const arrowTitle = collapsed ? 'Раскрыть' : 'Свернуть'

  return (
    <SidebarContainer>
      <Collapse className={collapsedClass}>
        <div>
          {ROUTES.map((route) => (
            <SidebarLink key={route.route} {...route} />
          ))}
        </div>
        <Arrow
          className={collapsedClass}
          src={'/static/sidebar/arrow.svg'}
          onClick={() => sidebarCollapse()}
          title={arrowTitle}
        />
      </Collapse>
    </SidebarContainer>
  )
}
