import React from 'react'
import { styled } from 'linaria/react'
import { NextPage } from 'next'

export const Box = styled.div`
  width: 100%;
  height: 100vh;
  padding: 70px 30px 30px 30px;
  & p {
    font-size: 17px;
    text-align: center;
    color: #c0c0c0;
    line-height: 1.1;
    & a {
      color: #0082ff;
    }
  }
`

const LogoutPage: NextPage = () => {
  return (
    <Box>
      <p>
        Был произведен выход из системы. Вы можете авторизоваться заново, для
        этого пройдите по <a href="/login">ссылке.</a>
        <br />
        <br />
        Также можете{' '}
        <a href="https://crowd.dialog.info/crowd/console/logoff.action">
          выйти
        </a>{' '}
        из системы CROWD
      </p>
    </Box>
  )
}

LogoutPage.getInitialProps = async () => {
  return {}
}
export default LogoutPage
