import { requestApi } from '../../../helpers/api'
import { UserApiResponse, UserType } from '../../../features/users/types'

export const userEndpoints = {
  login: function (phone: string): Promise<number> {
    return requestApi('post', '/login', { phone })
  },
  isAuthorized: function (): Promise<Record<string, string>> {
    return requestApi('get', '/is-authorized')
  },
  logout: function (): Promise<number> {
    return requestApi('post', '/logout')
  },
  getMe: function (): Promise<UserType> {
    return requestApi('get', '/users/me')
  },
  getAllUsers: function (): Promise<UserApiResponse> {
    return requestApi('get', '/users/getAllUsers')
  },
  updateUser: function (user: UserType): Promise<UserApiResponse> {
    return requestApi('post', '/users/update', user)
  },
  deleteUser: function (userId: number): Promise<UserApiResponse> {
    return requestApi('delete', '/users/delete', { userId })
  },
}
