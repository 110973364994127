import React, { useRef, useState } from 'react'
import { styled } from 'linaria/react'
import { PRIMARY_TEXT_COLOR, SECONDARY_COLOR } from '../../ui/constants'
import router from '../../routes'
import { checkAccess } from '../../helpers/user'
import { UserFlags } from '../section/constants'
import { useRouter } from 'next/router'

const { Link } = router

type SubMenuProps = {
  isShow: boolean
}

const ItemStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${PRIMARY_TEXT_COLOR};
  margin-right: 20px;
  padding: 0 20px;
  cursor: pointer;

  &:hover {
    background-color: ${SECONDARY_COLOR};
  }

  a {
    text-decoration: none;
    color: ${PRIMARY_TEXT_COLOR};
  }
`

const SubMenuWrapper = styled.div<SubMenuProps>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: ${(p) => (p.isShow ? '40px' : '0')};
  opacity: ${(p) => (p.isShow ? '1' : '0')};
  visibility: ${(p) => (p.isShow ? 'visible' : 'hidden')};
  position: absolute;
  top: 100px;
  right: 0;
  background-color: #1e1f23;
  transition: 300ms;
`

const MenuButton = styled.button<SubMenuProps>`
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-radius: 20px;
  margin-left: 30px;
  background-color: ${(p) => (p.isShow ? '#4566f6' : '#36373c')};
  color: ${PRIMARY_TEXT_COLOR};
  transition: 300ms;
`

const MenuIcon = styled.img``

export const SubMenu: React.FunctionComponent = () => {
  const [isShow, setIsShow] = useState(false)
  const router = useRouter()
  const pathname = router.pathname as string
  const route = useRef(pathname)

  if (pathname !== route.current) {
    setIsShow(false)
    route.current = pathname
  }

  // todo add checkAccess argument array
  const canManageUsers = checkAccess(UserFlags.CanManageUsers)
  const canManageThemes = checkAccess(UserFlags.CanManageThemes)
  const isInstagramExtension = checkAccess(UserFlags.CanUpdate)

  return (
    <>
      <MenuButton
        isShow={isShow}
        onClick={() => {
          setIsShow(!isShow)
        }}
      >
        <MenuIcon
          src={
            isShow
              ? '/static/header_menu_close.svg'
              : '/static/header_menu_open.svg'
          }
        />
        Меню
      </MenuButton>
      <SubMenuWrapper isShow={isShow}>
        {canManageThemes && (
          <Link route={'/topics'}>
            <ItemStyled>{'Редактирование / удаление тем'}</ItemStyled>
          </Link>
        )}
        <Link route={'/reports'}>
          <ItemStyled>{'Отчёты'}</ItemStyled>
        </Link>
        {canManageUsers && (
          <Link route={'/users'}>
            <ItemStyled>{'Пользователи'}</ItemStyled>
          </Link>
        )}
        <ItemStyled>
          <a
            target={'_blank'}
            rel={'noreferrer'}
            href={'/api/resources/documentation'}
            download={'documentation.pdf'}
          >
            {'Документация'}
          </a>
        </ItemStyled>
        {isInstagramExtension && (
          <ItemStyled>
            <a
              target={'_blank'}
              rel={'noreferrer'}
              href={'/api/resources/instagramExtension'}
              download={'instagram.zip'}
            >
              {'Расширение для Instagram'}
            </a>
          </ItemStyled>
        )}
      </SubMenuWrapper>
    </>
  )
}
