import {
  init,
  InitConfigRedux,
  RematchDispatch,
  RematchRootState,
  RematchStore,
} from '@rematch/core'
import { models, RootModel } from './models'

export function initializeStore(
  initialState?: InitConfigRedux,
): RematchStore<RootModel> {
  return init({
    models,
    redux: {
      initialState,
    },
  })
}

export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>
