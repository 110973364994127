import { createModel } from '@rematch/core'
import { userEndpoints } from './endpoints'
import { UserType } from '../../../features/users/types'

export type UserState = UserType & {
  isLoading: boolean
}

const initialState: UserState = {
  id: null,
  fio: null,
  phone: null,
  position: null,
  organizationAccess: null,
  isSuperuser: null,
  isBlocked: false,
  isDeleted: false,
  isLoading: false,
  crowdUserName: null,
  email: null,
}

export const user = createModel<UserState>()({
  state: initialState,
  reducers: {
    setUser(state, user: UserType): UserState {
      return {
        ...state,
        ...user,
      }
    },
    setLoading(state, isLoading: boolean): UserState {
      return {
        ...state,
        isLoading,
      }
    },
    reset(): UserState {
      return initialState
    },
  },
  effects: (dispatch) => ({
    async getCurrent(force = false, { user }): Promise<boolean> {
      if (!force && (user.isLoading || user.fio)) {
        return true
      }
      dispatch.user.setLoading(true)
      try {
        //todo remove isAuthorized
        const result = await userEndpoints.isAuthorized()
        if (result.fio) {
          const me = await userEndpoints.getMe()
          dispatch.user.setUser(me)
        }
        return true
      } catch (e) {
        return false
      } finally {
        dispatch.user.setLoading(false)
      }
    },
  }),
})
