import React, { useEffect, useState } from 'react'
import { Wrapper } from '../../ui/Wrapper'
import { styled } from 'linaria/react'
import { DEFAULT_BACKGROUND } from '../../ui/constants'
import { sectionEndpoints } from '../section/endpoints'
import { SearchDropDown } from './QuickSearch'
import { Button } from '../../ui/Button'
import router from '../../routes'
import { SubMenu } from './SubMenu'
import { userEndpoints } from '../../store/models/user/endpoints'
import { RootState } from '../../store/store'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { SectionState } from '../section/model'
import { OrganizationSelect } from './OrganizationSelect'

const { Link } = router

export const ExitButton = styled(Button)`
  background: #36373c;
  font-size: 14px;
  padding: 12px;
  margin-left: 10px;
  border-radius: 25px;
`

export const RecentlyDeletedButton = styled(ExitButton)`
  border-radius: 20px;
  padding: 12px 14px;
`

const HeaderWrapper = styled(Wrapper)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 250;
  padding: 0 20px 0 0;
  background: ${DEFAULT_BACKGROUND};
`

const Search = styled.div`
  position: relative;
`

const SearchInput = styled.input`
  background: #1a1a1e;
  border: none;
  height: 40px;
  width: 500px;
  border-radius: 60px;
  color: #fff;
  font-size: 16px;
  outline: none;
  text-indent: 20px;
  &::placeholder {
    color: #36373c;
  }
`

export const Header: React.FunctionComponent = () => {
  const sectionState = useSelector<RootState, SectionState>(
    (state) => state.section,
  )
  const { organization } = sectionState

  const queryInit = { text: '', organizationId: organization }
  const [query, setQuery] = useState(queryInit)
  const [result, setResult] = useState(null)

  const router = useRouter()
  const sectionName = router.query.sectionName as string

  useEffect(() => {
    if (!query.text) return
    ;(async () => {
      const result = await sectionEndpoints.search(query)
      setResult(result)
    })()
  }, [query.text])

  useEffect(() => {
    setQuery(queryInit)
  }, [organization])

  const closeSearch = (e: MouseEvent) => {
    if (query.text) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (e.target.getAttribute('data-block') !== 'search') {
        setResult(null)
        setQuery((st) => ({ ...st, text: '' }))
      }
    }
  }

  useEffect(() => {
    window.removeEventListener('click', closeSearch)
    window.addEventListener('click', closeSearch)
    return () => {
      window.removeEventListener('click', closeSearch)
    }
  }, [query.text])

  const logout = async () => {
    try {
      await userEndpoints.logout()
      return true
    } catch (e) {
      return false
    } finally {
      document.location.href = '/logout'
    }
  }

  return (
    <HeaderWrapper align={'center'} justify={'flex-end'}>
      <OrganizationSelect sectionName={sectionName} router={router} />
      <Search>
        <SearchInput
          placeholder={'Поиск'}
          value={query.text}
          data-block="search"
          onChange={(e) => {
            // if (!e.target.value && router.query.sectionName !== 'dashboard')
            // redirect(router.pathname + '/' + router.query.sectionName)
            setQuery({
              ...query,
              text: e.target.value,
              organizationId: organization,
            })
          }}
        />
        <Link route={'/search'}>
          <img
            src={'/static/search_advanced.svg'}
            height={40}
            alt={'search'}
            style={{
              cursor: 'pointer',
              margin: '0 50px 0 30px',
            }}
            title={'Расширенный поиск'}
          />
        </Link>
        <Link route={'/deleted'}>
          <RecentlyDeletedButton appearance={'dark'} title={'Удалённые записи'}>
            <img src={'/static/remove.svg'} height={16} alt={'deleted'} />
          </RecentlyDeletedButton>
        </Link>
        <ExitButton appearance={'dark'} onClick={logout}>
          <img
            src={'/static/exit.svg'}
            height={18}
            alt={'exit'}
            title={'Выход'}
          />
        </ExitButton>
        {query.text && result && (
          <SearchDropDown
            data={result}
            query={query.text}
            setQuery={setQuery}
          />
        )}
      </Search>
      <SubMenu />
    </HeaderWrapper>
  )
}
