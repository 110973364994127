import React from 'react'
import { removeCookie, setCookie } from '../../helpers/cookie'
import { SelectPicker } from 'rsuite'
import { useUserOrganizationOpts } from '../section/hooks'
import { RootState } from '../../store/store'
import { SectionState } from '../section/model'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateEffect } from '../../helpers/hooks'
import { ROUTES } from '../sidebar/constants'
import { redirect } from '../../helpers/redirect'
import { NextRouter } from 'next/router'
import { css } from 'linaria'
import { isNoVisibleRegions } from '../../helpers/user'

const OrgSelectStyle = css`
  width: 130px;
  margin: 0 auto 0 32px;
`

type Props = {
  sectionName: string
  router: NextRouter
}

export const OrganizationSelect: React.FunctionComponent<Props> = ({
  sectionName,
  router,
}) => {
  const dispatch = useDispatch()
  const sectionState = useSelector<RootState, SectionState>(
    (state) => state.section,
  )
  const userOrganizationOpts = useUserOrganizationOpts()
  const { organization } = sectionState
  const isBlocked = isNoVisibleRegions(null)

  useUpdateEffect(() => {
    //todo add function set cookie regionCode
    const route = ROUTES.find(
      (route) =>
        route.organization !== null && route.organization === organization,
    ).route
    removeCookie('regionCode', '/section')
    localStorage.removeItem('regionCode')
    dispatch.section.updateFilters({ regionCode: null })
    dispatch.section.setCheckedMode(false)
    if (isBlocked) {
      redirect('/blocked')
    }
    if (
      sectionName ||
      router.pathname === '/' ||
      router.pathname === '/blocked'
    ) {
      redirect(route)
    }
  }, [organization])

  return (
    <SelectPicker
      className={OrgSelectStyle + ' filterSelect'}
      menuClassName={'filterSelectMenu'}
      searchable={false}
      cleanable={false}
      placeholder={'Организация'}
      value={organization}
      onChange={(value) => {
        const date = new Date()
        setCookie('organization', value, {
          path: '/',
          expires: new Date(date.setMonth(date.getMonth() + 1)),
        })
        dispatch.section.setOrganization(value)
      }}
      data={userOrganizationOpts}
      preventOverflow={true}
    />
  )
}
