import React from 'react'
import { styled } from 'linaria/react'
import { userEndpoints } from '../store/models/user/endpoints'

type UserBlockedProps = {
  warningText: string
}

const Box = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Heading = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: -0.48px;
  color: #9593a0;
`

const Button = styled.a`
  margin-top: 40px;
  padding: 10px;
  border-radius: 8px;
  background: #4566f6;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background 0.2s ease;
  & p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.31;
    letter-spacing: -0.32px;
    color: #fff;
  }
  &:hover {
    cursor: pointer;
    background: #2b4de1;
  }
`

const ButtonIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M8 16.5V17.5C8 18.2956 8.31607 19.0587 8.87868 19.6213C9.44129 20.1839 10.2044 20.5 11 20.5H18C18.7956 20.5 19.5587 20.1839 20.1213 19.6213C20.6839 19.0587 21 18.2956 21 17.5V7.5C21 6.70435 20.6839 5.94129 20.1213 5.37868C19.5587 4.81607 18.7956 4.5 18 4.5H11C10.2044 4.5 9.44129 4.81607 8.87868 5.37868C8.31607 5.94129 8 6.70435 8 7.5V8.5M13 16.5L17 12.5L13 16.5ZM17 12.5L13 8.5L17 12.5ZM17 12.5H3H17Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const UserBlocked: React.FunctionComponent<UserBlockedProps> = ({
  warningText,
}) => {
  const logout = async () => {
    try {
      await userEndpoints.logout()
      return true
    } catch (e) {
      return false
    } finally {
      document.location.href =
        'https://crowd.dialog.info/crowd/console/logoff.action'
    }
  }

  return (
    <Box>
      <Heading>{warningText}</Heading>
      <Button onClick={logout}>
        {ButtonIcon}
        <p>Выйти из системы</p>
      </Button>
    </Box>
  )
}
