import { useEffect, useRef } from 'react'
import MatomoTracker from '@datapunt/matomo-tracker-js'
import getNextConfig from 'next/config'
import { useRouter } from 'next/router'
const config = getNextConfig()
const { MATOMO_URL, MATOMO_SITE_ID } = config.publicRuntimeConfig

export const useMatomo = (userId: number, userName: string): void => {
  const router = useRouter()
  const trackerRef = useRef<MatomoTracker>(null)

  useEffect(() => {
    if (MATOMO_URL && MATOMO_SITE_ID) {
      if (trackerRef.current === null) {
        trackerRef.current = new MatomoTracker({
          urlBase: MATOMO_URL,
          siteId: MATOMO_SITE_ID,
          userId: userId ? userId.toString() : undefined,
          heartBeat: {
            active: true,
            seconds: 10,
          },
          linkTracking: false,
          configurations: {
            disableCookies: true,
            setSecureCookie: true,
            setRequestMethod: 'POST',
          },
        })
      } else {
        if (userId) {
          trackerRef.current.pushInstruction('setUserId', userId.toString())
          if (userName) {
            trackerRef.current.pushInstruction(
              'setCustomVariable',
              1,
              'name',
              userName,
              'visit',
            )
          }
        } else {
          trackerRef.current.pushInstruction('resetUserId')
        }
      }

      trackerRef.current.trackPageView()
      const onRouteChangeComplete = () => {
        trackerRef.current.trackPageView()
      }
      router.events.on('routeChangeComplete', onRouteChangeComplete)
      return () => {
        router.events.off('routeChangeComplete', onRouteChangeComplete)
      }
    }
  }, [userId])
}
